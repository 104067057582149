import React from 'react'
import { graphql } from 'gatsby'

// Ours
import SEO from '../components/SEO'
import stripTrailingSlash from '../utils/strip-trailing-slash'
import { BlockWrapper } from '../components/MainBlocks'
import ProjectsListing from '../components/ProjectsListing'
import CallToAction from '../components/CallToAction'

const OurWorkPage = props => {
  const data = props.data
  let title = 'Our Work'
  let slugPrefix = stripTrailingSlash(props.location.pathname)

  return (
    <div>
      <SEO title={title} />
      <h1 className="hide">{title}</h1>
      {data.projects ? (
        <BlockWrapper maxWidth={6} bg="transparent">
          <ProjectsListing
            edges={data.projects.edges}
            slugPrefix={slugPrefix}
          />
        </BlockWrapper>
      ) : (
        <div className="min-height-25vh" />
      )}
      <CallToAction />
    </div>
  )
}

export default OurWorkPage

export const pageQuery = graphql`
  query OurWorkQuery {
    projects: allWpProject(
      sort: { order: DESC, fields: date }
      filter: {
        slug: { ne: "placeholder-project" }
        projectDetails: { projectStatus: { eq: "completed" } }
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          title
          slug
          uri
          details {
            description
          }
          projectDetails {
            projectStatus

            # client
            # year
            location
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1280, maxHeight: 1280, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
